import React from 'react';
import Img from '../../assets/Lucknow-Games.jpg';

const Home = () => {
  return (
    <div className='lg:py-10 lg:px-40 p-5'>
      <p className='logo text-gray-800 text-2xl lg:text-3xl'>
        Lucknow Game
      </p>
    <div className=' flex flex-col items-center'>
      <div className="relative w-full flex justify-center pt-5">
        <img
          src={Img}
          alt="Lucknow Game"
          className="w-full h-auto lg:w-[65vw]"
        />
      </div>
      <button className="m-5 w-[20rem] bg-blue-600 hover:bg-gray-800 text-white py-2 px-4 rounded-none w-40 transition-colors duration-300">
         <a href="http://lucknowgames.vip/register?invite_code=361F88296164">Register On Lucknow Games App</a> 
      </button>
      <table className="table-auto border-collapse border border-gray-800 w-full mb-5">
        <tbody>
          <tr>
            <td className="border border-gray-800 px-4 py-2">Platform Name</td>
            <td className="border border-gray-800 px-4 py-2 font-semibold">Lucknow Game App</td>
          </tr>
          <tr>
            <td className="border border-gray-800 px-4 py-2">Lucknow Games Invite Code</td>
            <td className="border border-gray-800 px-4 py-2 font-semibold">361F88296164</td>
          </tr>
          <tr>
            <td className="border border-gray-800 px-4 py-2">Available Games In Lucknow Games</td>
            <td className="border border-gray-800 px-4 py-2 font-semibold">Win Go, Trx Hash, 5D Lotre, K3 Lotre</td>
          </tr>
          <tr>
            <td className="border border-gray-800 px-4 py-2">Recharge Bonus</td>
            <td className="border border-gray-800 px-4 py-2 font-semibold">2% Cashback On USDT Recharge</td>
          </tr>
        </tbody>
      </table>
    </div>
      <p className='py-2'>
        Customer Support – <a href="http://lucknowgames.vip/register?invite_code=361F88296164" className="text-blue-500 hover:underline">http://lucknowgames.vip/register?invite_code=361F88296164</a>
      </p>
      <p className='py-2'>
        Lucknow Predictions Channel – <a href="https://t.me/+c7kHtsEmrIoxMjM9" className="text-blue-500 hover:underline">https://t.me/+c7kHtsEmrIoxMjM9</a>
      </p>

    </div>
  )
}

export default Home;
