import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className='lg:py-10 lg:px-40 p-5'>
      <div className="max-w-screen-xl mx-auto text-left">
        <p className='logo text-gray-800 text-2xl lg:text-3xl py-5'>
          Privacy Policy
        </p>
        <p className='logo text-gray-800 font-semibold text-2xl lg:text-3xl py-5'>
          Who we are
        </p>
          <p className="text-gray-800">
          Our website address is:  &nbsp;
            <a href="https://lucknow-gamee.in/" className="text-blue-500 hover:underline">
            https://lucknow-gamee.in/
            </a>.
          </p>
      </div>
    </div>
  )
}

export default PrivacyPolicy
